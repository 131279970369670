import * as React from 'react'
import Layout from '../components/layout'
import GridFilter from '../components/GridFilter'
import '../styles/global.css'
import {Helmet} from "react-helmet";

 

export default function Projects() {
  return (
    <Layout pageTitle="Projects">
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Projects - USI</title>
        <meta name="description" content="We have a simple philosophy and established methodology that allows us to deliver a variety of outputs across a range of different projects."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

       <div className="bg-blue-900 md:p-8 top">
            <div className="container w-full md:w-1/2 p-5 sm:pl-12 pb-16 pt-16 lg:pt-24">
                <h2 className="text-6xl md:text-7xl text-white font-heading">
                    Our&nbsp;Projects
                    <br/>
                </h2>
                <p className="text-2xl text-white pt-9">Our previous projects covering a variety of fields in the places we live, work and play.</p>
            </div>
        </div>
      <GridFilter />
    </Layout>
  )
}
