import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import '../styles/global.css'
import Zoom from 'react-reveal/Fade' // Importing Zoom effect


const projects = [
    { id: '1', projectName: 'Banana Block', image: "/img/projects/bg/banana-block-camera-shot.webp", tag: 'Public Space & Placemaking', client: 'Tourism NI Fund', page: '../projects/banana-block'},
    { id: '2', projectName: 'Portview', image: "/img/projects/portview-edwards-image.webp", tag: 'Public Space & Placemaking', client: 'Portview Trade Center', page: '../projects/portview'},
    { id: '3', projectName: 'Future Foyle', image: "/img/videos/foyle_projects_gif.gif", tag: 'Mental Health & Wellbeing', client: 'Public Health Agency NI', page: '../projects/future-foyle'},
    { id: '4', projectName: 'Weaver\'s Cross', image: "https://i2-prod.belfastlive.co.uk/incoming/article19872971.ece/ALTERNATES/s615b/0_1527-BelfastTransportHub_View27_004.jpg", tag: 'Public Space & Placemaking', client: 'Translink', page: '../projects/weavers-cross'},
    { id: '5', projectName: 'Our Place in Space', image: "https://unboxed2022.uk/sites/default/files/styles/uncropped_medium/public/2021-10/1.%20Derry_Londonderry%20Hero%20Graphic.jpg?itok=lES0AUam", tag: 'Culture & Tourism', client: 'Unboxed 2022', page: '../projects/ourplaceinspace'},
    { id: '7', projectName: 'Lighting Strategy', image: "/img/videos/lighting_projects_gif.gif", tag: 'Lighting & Installation', client: 'Belfast City Council', page: '../projects/lighting'},
    { id: '6', projectName: 'A City Imagining', image: "/img/projects/shop-left.webp", tag: 'Public Space & Placemaking', client: 'Belfast City Council', page: '../projects/cityimagining'},
    { id: '8', projectName: 'COP 26', image: "/img/projects/cop-sketch.webp", tag: 'Climate & Resilience', client: 'Oliver Jeffers', page: '../projects/cop26'},
    { id: '9', projectName: 'Belfast Zoo', image: "/img/videos/branding-gif-artwork.gif", tag: 'Public Space & Placemaking', client: 'Belfast City Council', page: '../projects/zoo'},
    { id: '10', projectName: 'Relink', image: "/img/projects/bg/pillar-relink.webp", tag: 'Mental Health & Wellbeing', client: 'Public Health Agency', page: '../projects/relink'},
    { id: '11', projectName: 'The Disappearing Wall', image: "/img/projects/disappearing.webp", tag: 'Climate & Resilience', client: 'Goethe Institut', page: '../projects/disappearingwall'},
    { id: '12', projectName: 'Fermanagh Futures', image: "/img/videos/fermanagh_projects_gif.gif", tag: 'Culture & Tourism', client: 'Fermanagh Tourism', page: '../projects/fermanagh'},
    { id: '14', projectName: 'AMTCE', image: "/img/projects/bg/amtce-bg.png", tag: 'Technology for Good', client: 'Advanced Manufacturing Training Centre of Excellence', page: '../projects/amtce'},
    { id: '16', projectName: 'What\'s the Story?', image: "/img/videos/wts_projects_gif.gif", tag: 'Culture & Tourism', client: 'Portview Trade Center', page: '../projects/whatsthestory'},
    { id: '15', projectName: 'Road to Reinvention', image: "/img/projects/bg/reinvention.webp", tag: 'Climate & Resilience', client: 'Urban Scale Interventions', page: '../projects/reinvention'},
    { id: '17', projectName: 'Northwest Transport Hub', image: "/img/projects/bg/transporthub.webp", tag: 'Culture & Tourism', client: 'Translink and Turleys', page: '../projects/transport'},
    { id: '13', projectName: 'Latrobe', image: "/img/videos/latrobe_projects_gif.gif", tag: 'Mental Health & Wellbeing', client: 'Latrobe Valley Health Assembly', page: '../projects/latrobe'},
    { id: '18', projectName: 'Bright Ideas', image: "/img/projects/bg/brightideas.webp", tag: 'Mental Health & Wellbeing', client: 'Public Health Agency', page: '../projects/healthyplaces'},
    { id: '19', projectName: 'Belfast Outdoor Spaces', image: "/img/projects/bg/outdoor-spaces-red.webp", tag: 'Culture & Tourism', client: 'Belfast City Council', page: '../projects/outdoorspaces'},
    { id: '20', projectName: 'Christmas Lighting', image: "/img/videos/christmas_projects_gif.gif", tag: 'Lighting & Installation', client: 'Belfast City Council', page: '../projects/christmaslighting'},
    { id: '21', projectName: 'Belfast Resilience Strategy', image: "/img/projects/bg/resilience.webp", tag: 'Climate & Resilience', client: 'Belfast City Council', page: '../projects/resilience'},
    { id: '22', projectName: 'Belfast Maritime Festival', image: "/img/projects/bg/maritime.webp", tag: 'Culture & Tourism', client: 'Belfast City Council', page: '../projects/maritime'},
    { id: '23', projectName: 'Driverless Futures', image: "https://ik.imagekit.io/usi/project-page/Transport_Museum-Clean_1_u7QhwI9wb.png", tag: 'Technology for Good', client: 'London Transport Museum', page: '../projects/driverless'},
    { id: '24', projectName: 'Whitley Bay', image: "/img/projects/bg/whitley-bg.webp", tag: 'Culture & Tourism', client: 'Nexus', page: '../projects/whitley'},
    { id: '25', projectName: 'Soda & Champ Christmas Shop', image: "/img/projects/bg/christmas.webp", tag: 'Culture & Tourism', client: 'Belfast City Council', page: '../projects/christmasshop'}
];

export default function GridFilter() {
    const [tag, setTag] = useState('all');
    const [ filteredProjects, setFilteredProjects] = useState([]);

    useEffect ( () => {
        tag === 'all' ? setFilteredProjects(projects) : setFilteredProjects(projects.filter( project => project.tag === tag))
    }, [tag])

    return (
        <div handleSetTag={setTag} className="pt-16 lg:w-11/12 xxl:w-10/12 mx-auto">            
            <div className="tags border-t-2 border-b-2 border-black mx-8 m-auto text-sm text-left">
              <TagButton name="all" handleSetTag={setTag}/> -
              <TagButton name="Public Space & Placemaking" handleSetTag={setTag}/> -
              <TagButton name="Mental Health & Wellbeing" handleSetTag={setTag}/> -
              <TagButton name="Culture & Tourism" handleSetTag={setTag}/> -
              <TagButton name="Climate & Resilience" handleSetTag={setTag}/> -
              <TagButton name="Technology for Good" handleSetTag={setTag}/> -
              <TagButton name="Lighting & Installation" handleSetTag={setTag}/>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-6 lg:w-full flex place-items-center m-auto">
                { filteredProjects.map( project =>
                <Zoom>
                  <Link to={project.page}>
                  <div className="card-container" key={project.id}>
                    <img className="card-image transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 object-cover" src={project.image} alt = ""/>
                    <p className="text-left p-2 text-black">{ project.projectName }<br/><span class="text-gray-500 leading-3">{ project.client }</span></p>
                  </div>
                  </Link>
                </Zoom>)}
            </div>
        </div>
    )
}

const TagButton = ( {name, handleSetTag } ) => {
    return <button className="p-4" onClick={ () => handleSetTag(name)}>{ name.toUpperCase() }</button>;
}